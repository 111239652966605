

.link-plus {
    border: 1px solid #FFFFFF;
    height: calc(37rem/16);
    width: calc(37rem/16);
    border-radius:50%;
    font-size:calc(14rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    transition: all 0.9s ease;
}

.link-plus:hover {
    background-color:var(--color-primary);
    border-color:var(--color-primary);
}


