
.cookie-service-enable__btn {
    margin-top: calc(10rem/16);
}
.cookie-service-enable{
    position: absolute;
    z-index: 2;
    top: calc(30rem/16);
    left: 50%;
    transform: translateX(-50%);
}
.cookie-service-enable__text {
    text-align:center;
    max-width: calc(360rem/16);
}