
.rounded-checkbox {
    position:relative;
}
.rounded-checkbox:before,
.rounded-checkbox:after {
    content: " ";
    display: table;
}.rounded-checkbox:after {
     clear: both;
 }
.rounded-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.rounded-checkbox__box {
    position: relative;
    float: left;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid #c4c6c7;
    background: #fff;
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius:50%;
    margin-top: .1em;
}
.rounded-checkbox__box:before {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: calc(9rem/16);
    color: #fff;
    visibility: hidden;
}
.rounded-checkbox__input:checked ~ .rounded-checkbox__box:before {
    visibility: visible;
}

.rounded-checkbox__input:checked ~ .rounded-checkbox__box {
    background: var(--bg-primary-gradient);
    border-color:#fff;
}

.has-error .rounded-checkbox__box {
    border-color: var(--color-danger)
}
.rounded-checkbox__text {
    display: block;
    overflow: hidden;
    font-size:calc(14rem/16);
    font-family:var(--font-default);
}