/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}
.wysiwyg ul {
    padding-left: 20px;

}
.wysiwyg ul li {
    margin-bottom:calc(15rem/16);
    padding-left:5px;
    list-style-position: inside;
}


.wysiwyg a, .link {
    color:var(--color-primary-light);
}
.wysiwyg a:hover, .link:hover {
    color:var(--color-primary);
}
*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}
.table-responsive, .wysiwyg {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;

}


.wysiwyg a {
    color:var(--color-primary);
    font-family:var(--font-default-bold);
}
figure {
    margin:0;
}