.map-filter-overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-top: 0;
    left:0;
    width:calc(305rem/16);
    box-shadow: 0 20px 25px 0 rgba(0,0,0,0.2);
    transform: translateX(-100%);
    transition: transform 400ms ease-in-out, visibility 0.001ms 400ms, opacity 400ms ease;
    background: white;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    color: var(--color-text-default);
    @media screen and (max-width:767px) {
        padding-left:0;
        transform: translateY(-100%);
        width:calc(260rem/16);
        transition: transform 250ms ease-in-out;
    }
}

.map-filter-overlay > .form {
    display: flex;
    flex-direction: column;
    position: absolute;
}
.map-filter-overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    z-index: 1;
    transition: transform 400ms ease-in-out, visibility 0.001ms, opacity 1ms ease;
    @media screen and (max-width: 767px) {
        z-index: 11;
        transform: translateY(0);
        top: calc(40rem/16);
        transition: transform 250ms ease-in-out, visibility 0.001ms, opacity 1ms ease;
    }
}

.map-filter-overlay__header {
    background: linear-gradient(45deg, #4CA0BF 0%, #6FAAC0 48.18%, #92D1E8 100%);
    font-size:calc(20rem/16);
    line-height:calc(35/20);
    text-align:center;
    display:flex;
    justify-content:center;
    font-family:var(--font-default-bold);
    align-items:center;
    color:#fff;
    padding-top:calc(20rem/16);
    padding-bottom:calc(20rem/16);
}

.map-filter-overlay__content {
    padding: calc(30rem/16) calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(15rem/16) calc(20rem/16);

    }
}

.map-filter-overlay__footer {
    position: relative;
    bottom: 0;
    width: 100%;
    padding-left: calc(20rem/16);
    padding-right: calc(20rem/16);
    z-index: 11;
    padding-bottom:calc(5rem/16);
    background-color:#fff;
}

.map-filter-overlay .quick-filter {
    border-color:transparent;
    padding: 0;
    line-height:1;
    display:block;
    margin-bottom:calc(10rem/16);
}
.map-filter-overlay .quick-filter--group-parent {
    color:var(--color-primary);
    font-size:calc(14rem/16);
    text-transform:uppercase;
    border-bottom:1px solid var(--color-primary);
    border-radius:0;
    padding-bottom:calc(5rem/16);
}

.map-filter-overlay .quick-filter .icon {
    font-size:calc(9rem/16);
}