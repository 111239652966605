.thumb-slider {
    position:relative;
}
.thumb-slider__main-item {
    position:relative;
    padding-bottom:calc(30rem/16);
}
.thumb-slider__thumbs-wrapper {
    height:calc(81rem/16);
    margin-top:calc(-69rem/16);
    @media screen and (max-width: 767px) {
        height:calc(60rem/16);
        margin-top:calc(10rem/16);
    }
}
.thumb-slider__bg {
    width:100%;
    height:80%;
    background-color:var(--color-lightgrey);
}
.thumb-slider .slick-dots {
    display:none !important;
}
.thumb-slider__thumbs .slick-slide {
    position:relative;
    border:1px solid #ffff;
    border-left: 1px solid transparent;
    cursor:pointer;
}
.thumb-slider__thumbs .slick-slide + .slick-slide {
    border-left:1px solid #fff;
}
.thumb-slider__thumbs .slick-slide:not(.slick-current):before {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(255,255,255,0.3);
}
.thumb-slider__arrow  {
    background-color:var(--color-lightgrey);
    color:var(--color-primary);
    font-size:calc(16rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
    width:calc(63rem/16);
    height:100%;
    @media screen and (max-width: 767px) {
        background-color:transparent;
        background: var(--bg-primary-gradient);
        color:#fff;
        width: calc(25rem/16);
        height:calc(50rem/16);
    }
}
.thumb-slider__arrow.slick-prev {
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 50%;
        left:0;
        z-index: 2;
        transform: translateY(-100%);
    }
}
.thumb-slider__arrow.slick-next {
    @media screen and (max-width: 767px) {
        position: absolute;
        top: 50%;
        right:0;
        z-index: 2;
        transform: translateY(-100%);
    }
}
.thumb-slider__arrow.slick-disabled {
    color:var(--color-grey);
}
.thumb-slider__content {
    background-color:#fff;
    padding:calc(9rem/16) calc(19rem/16);
    font-size:calc(14rem/16);
    line-height:calc(16/14);
}
.thumb-slider__content-wrapper {
    position: absolute;
    width: 100%;
    margin-top: calc(-40rem/16);
    bottom:0;

}
.thumb-slider__content-title {
    font-size:calc(20rem/16);
    line-height:calc(35/20);
    color:var(--color-primary);
    font-family:var(--font-default-bold);
}
.thumb-slider__main-item img {
    transition: all 1s cubic-bezier(0.4, 0, 0.3, 1);
    -webkit-backface-visibility: hidden;
    transform: scale(1.2);
}
.slick-active .thumb-slider__main-item img {
    transform: scale(1);
}
