.navbar-affix-placeholder {
    height: calc(150rem / 16);
    @media screen and (max-width: 767px) {
        height:calc(58rem/16);
    }
}

.is-affix .navbar-brand.navbar-brand {
    @media screen and (min-width: 768px) {
        transform: translateY(-16%);
    }
}

.is-affix .navbar-brand__logo {
    scale: 0.85;
    transform: translateY(1.75rem);

    @media screen and (max-width: 767px) {
        scale: 1;
        transform: translateY(0rem);
    }
}

.is-affix .navbar-brand__background-blob {
    transform: translateX(1.75rem) rotate(10deg);

    @media screen and (max-width: 767px) {
        transform: translateX(1rem) rotate(10deg);
    }
}

.is-affix.navbar-wrapper {
    background-color: #fff;
}

.navbar-wrapper {
    position: absolute;
    width: 100%;
    z-index: 3;
    background-color: transparent;
    transition: background-color 0.4s ease-in-out;
}

.modal-open .navbar-wrapper {
    padding-right: calc(17rem / 16);
}

.navbar-wrapper--dark {
    position: fixed;
}

.navbar-wrapper--all-white:not(.is-affix) .nav-item__button{
    background:#fff;
    color:var(--color-primary);
}

.navbar-wrapper--all-white:not(.is-affix) .menu-icon__line {
    background:#fff;
}
.is-main-nav-open .navbar {
    @media screen and (max-width: 767px) {
        background-color: #fff;
    }
}

.navbar {
    padding: 0;
    z-index: 16;
    align-items: flex-start;
    padding-bottom: calc(9rem / 16);
}

.navbar-right {
    padding-top: calc(13rem / 16);
    @media screen and (max-width: 767px) {
        display: block;
    }
}

.navbar-brand.navbar-brand {
    padding: 0;
    width:calc(295rem/16);
    background-color: transparent;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    position: absolute;
    transition: 0.4s ease-in-out all;

    @media screen and (max-width: 767px) {
        width: calc(10.875rem);
    }
}

.navbar-brand__background-blob {
    height: 100%;
}

.navbar-brand__logo,
.navbar-brand__background-blob {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    width: 100%;
    position: relative;
    top: -5rem;

    @media screen and (max-width: 767px) {
        top: -3.5rem;
    }

    transition: 0.4s ease-in-out all;
}

.navbar-brand__logo {
    position: relative;
    padding: 3.5rem;
    align-self: center;
    top: -4.5rem;

    @media screen and (max-width: 767px) {
        padding: 2.5rem;
        top: -3rem;
        transform-origin: center left;
    }
}

/*
.navbar-brand img {
    height:calc(155rem/16);
    @media screen and (max-width: 767px) {
        height:auto;
        width: calc(78rem / 16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        height:calc(120rem/16);
    }
}
 */



.nav-item {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    margin-left: calc(15rem / 16);
    margin-right: calc(15rem / 16);
    font-size: calc(16rem / 16);
    font-family: var(--font-default);
    @media screen and (max-width: 767px) {
        display: inline-flex;
        margin-left: calc(5rem / 16);
        margin-right: calc(5rem / 16);
    }
}

.navbar-wrapper--dark .nav-item, .is-nav-dark .nav-item {
    color: var(--color-text-default);
}

.is-nav-dark .nav-item .icon {
    color: var(--color-primary);
}

.nav-item--menu {
    width: calc(82rem / 16);
    margin-right: 0;
    margin-left:0;
}

.nav-item a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-item .icon {
    font-size: calc(30rem / 16);
    padding-right: calc(10rem / 16);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);

    }

}

.navbar-wrapper--dark .icon {
    color: var(--color-primary);
}

.nav-item .icon-person {
    font-size: calc(25rem / 16);
}

.nav-item .nav-item__button {
    font-size: calc(16rem / 16);
    padding-left: calc(30rem / 16);
    padding-right: calc(30rem / 16);
}

.nav-item--menu button:focus {
    box-shadow: none;
    border: none;
    outline: none;
}