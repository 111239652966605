

.main-footer {
    background-color:var(--color-secondary);
    color:#fff;
    padding-top:calc(51rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(30rem/16);
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
        padding-left:calc(20rem/16);
        padding-right:calc(20rem/16);
    }

}
.main-footer a {
    transition: all 0.2s ease;
}
.main-footer a:hover {
    color:var(--color-primary);
}


.main-footer__headline {
    font-size:calc(20rem/16);
    line-height:calc(35/20);
    font-family:var(--font-default-bold);
    margin-bottom:calc(15rem/16);
    @media screen and (max-width: 767px) {
        margin-bottom:0;
    }
}

.main-footer__address .icon {
    color:var(--color-primary-light);
}

.main-footer__address .icon-location {
    font-size:calc(26rem/16);
}

.main-footer__address .icon-phone {
    font-size:calc(22rem/16);
}


.main-footer__address .icon-mail {
    font-size:calc(14rem/16);
}


.main-footer__meta-content {
    border-top: 1px solid rgba(255,255,255,0.2);
    padding: calc(22rem/16) 0;
}

.main-footer__meta-content-list li{
    display:inline-block;
    @media screen and (max-width: 767px) {
        display:block;
        text-align:center;
    }
}

.main-footer__main-content {
    padding-bottom:calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom:calc(30rem/16);

    }
}

.main-footer__meta-content-list li + li{
    @media screen and (min-width: 768px) {
        padding-left:calc(44rem/16);

    }
}
.main-fotter__logo-elements {
    max-width: calc(90rem/16);
    margin-left:calc(10rem/16);
}