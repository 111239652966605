.img-teaser {
    position: relative;
    @media screen and (min-width: 768px) {
        overflow:hidden;
    }
}
.img-teaser:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    width: calc(100% + 1px);
    height: 100%;
    background: linear-gradient(180deg, rgba(57, 57, 57, 0) 0%, rgba(57, 57, 57, 0.8) 100%);
    transition: all 0.9s ease;
    z-index: 1;
}
.img-teaser__body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    flex-direction: column;
    padding: calc(35rem / 16);
    z-index: 2;
    @media screen and (max-width: 767px) {
        padding:calc(20rem/16);
    }
}
.img-teaser__title {
    font-size: calc(24rem / 16);
    margin-top: calc(5rem / 16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);
    }
}

.img-teaser__date {
    font-size: calc(22rem / 16);
    font-family: var(--font-default-light);
}

.img-teaser__body {
    transition: all 0.6s ease-in-out;
}

.img-teaser:hover .img-teaser__body {
    transform: translateY(-20px);
    backface-visibility: hidden;
}

.img-teaser .link-plus {
    transition: all 0.5s ease;
}

.img-teaser:hover .link-plus {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    transform: scale(2) translateY(-40px);

}
.img-teaser .img-teaser__img {
    transition: all 1.5s ease;
    backface-visibility: hidden;
}
.img-teaser:hover .img-teaser__img {
    transform: scale(1.02);
    transition: all 2.4s ease;
    backface-visibility: hidden;
}
.img-teaser:hover:before {
    opacity: 0.3;
}
.img-teaser:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(146, 209, 232, 0);
    transition: all 0.9s ease;
    z-index: 1;
}
.img-teaser:hover:after {
    background-color: rgba(128, 183, 203, 0.4);
}
.img-teaser__img-wrapper {
    @media screen and (min-width: 768px) {
        width:calc(100% + 1px);
    }
}
