

.teaser-slider__item {
    padding-left:calc(15rem/16);
    padding-right:calc(15rem/16);
    @media screen and (max-width: 767px) {
        padding-left:calc(7rem/16);
        padding-right:calc(7rem/16);
    }
}


.teaser-slider__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
    color:var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    @media screen and (max-width: 991px) {
        display: inline;
        text-align: center;
        position:relative;
        margin-left:calc(5rem/16);
        margin-right:calc(5rem/16);
    }
}


.teaser-slider__arrow.slick-disabled {
    color:var(--color-grey);
    pointer-events:none;
}
.teaser-slider__arrow:not(.slick-disabled):hover {
    color:var(--color-primary-light);
}

.teaser-slider__arrow.arrow-prev {
    left:0;
    @media screen and (min-width: 992px) and (max-width: 1640px) {
        left: 40%;
        bottom: calc(20rem/16);
        top: initial;
    }
    @media screen and (min-width: 1641px) {
        margin-left: -80px;

    }
}
.teaser-slider__arrow.arrow-next {
    right:0;
    @media screen and (min-width: 992px) and (max-width: 1640px) {
        right: 40%;
        bottom: calc(20rem/16);
        top: initial;
    }

    @media screen and (min-width: 1641px) {
        margin-right: -80px;
        right:0;
    }
}

.teaser-slider--hide-dots .slick-dots {
    display: none !important;
    visibility: hidden;
}

@media screen and (max-width: 767px) {
    .teaser-slider--mobile-center .slick-slide {
        position:relative;
    }
    .teaser-slider--mobile-center .slick-slide:before{
        content:'';
        display:block;
        position:absolute;
        background-color:rgba(255,255,255,0.3);
        width:100%;
        height:100%;
        z-index: 1;
        transition:all 0.2s ease;
    }

    .teaser-slider--mobile-center .slick-current:before{
        background-color:transparent;
        z-index: -1;
        visibility: hidden;
    }
}
