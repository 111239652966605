

/* ------ video-slider ------ */

.video-slider__play {
    font-size:calc(90rem/16);
    color:#fff;
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    @media screen and (max-width: 767px) {
        font-size:calc(22rem/16);
    }
}

.video-slider__play .icon {
    @media screen and (max-width: 767px) {
        position:absolute;
        top: calc(20rem/16);
    }

}
.video-slider__title {
    color:var(--color-primary-light);
    font-size:calc(25rem/16);
    font-family:var(--font-default-bold);
    line-height:calc(35/25);
    text-align:center;
    max-width:calc(550rem/16);
    margin:0 auto;
    z-index: 3;
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }
}

.video-slider__item, .video-slider .slick-slide {
    @media screen and (min-width: 1241px) {
        width:800px;
    }
    @media screen and (max-width: 1240px) and (min-width: 768px) {
        width:800px;
    }
    @media screen and (max-width: 767px) {
        width:100%;
    }

}
.video-slider__item {
    padding: 0 calc(15rem/16);
    @media screen and (max-width: 767px) {
        padding:0;
    }
}

.video-slider__item__text-overlay:before {
    @media screen and (min-width: 768px) {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0,0,0,-0.01) 0%, rgba(0,0,0,0) 0%, #000000 100%);
        top: 0;
        transition: all 0.6s ease;
        left: 0;
    }
}
.video-slider__item:hover .video-slider__item__text-overlay:before {
    @media screen and (min-width: 768px) {
        background-color: rgba(128,183,203,.4);
    }
}
.video-slider__item .video-thumb {
    @media screen and (min-width: 768px) {
        transition: all 0.5s ease;
    }
}
.video-slider__item:hover .video-thumb {
    @media screen and (min-width: 768px) {
        transform: scale(1.03);
    }
}

.video-slider__item__text-overlay {
    padding:calc(40rem/16);
    position:absolute;
    left:0;
    right:0;
    width:100%;
    height:100%;
    top:0;
    display: flex;
    align-items:flex-end;
    flex-direction:column;
}