.lunch-teaser__item+.lunch-teaser__item{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(70rem/16);
    }
}

.lunch-teaser__item:first-of-type .lunch-teaser__body{
    border-top-right-radius: 0;

    @media screen and (min-width: 768px){
        border-top-right-radius: 20px;
    }
}

.lunch-teaser__item:first-of-type .lunch-teaser__img{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    @media screen and (min-width: 768px){
        border-top-right-radius: 0;
    }
}

.lunch-teaser__info{
    font-size: calc(14rem/16);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: calc(12rem/16) 0 calc(14rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        flex-direction: row;
        align-items: center;
        margin: calc(12rem/16) 0 calc(14rem/16);
    }

    @media screen and (min-width: 1200px) {
        margin: calc(18rem/16) 0 calc(20rem/16);
    }
}

.lunch-teaser__info-item{
    display: flex;
    align-items: center;
}

.lunch-teaser__info-item:not(:last-of-type){
    @media screen and (min-width: 767px){
        margin-right: calc(30rem/16);
    }
}

.lunch-teaser__body{
    background-color: #FFF;
    padding: calc(35rem/16) 0;

    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) calc(30rem/16);
    }

    @media screen and (min-width: 1200px) {
        padding: calc(35rem/16);
    }
}

.lunch-teaser__title{
    font-size: calc(20rem/16);
    line-height: 1.2;
    font-family: var(--font-default-bold);
    color: var(--color-primary);

    @media screen and (min-width: 768px) {
        font-size: calc(25rem/16);
    }
}

.lunch-teaser__content{
    font-size: calc(14rem/16);
    line-height: 20px;
    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        line-height: 28px;
    }
}

.lunch-teaser__content p+p {
    margin: calc(6rem/16) 0;

    @media screen and (min-width: 1200px) {
        margin: calc(10rem/16) 0;
    }
}

.lunch-teaser__button {
    display: block;
    margin-top: calc(20rem/16);

    @media screen and (min-width: 1200px) {
        display: inline-block;
        margin-top: calc(30rem/16);
    }
}

.lunch-teaser__button + .lunch-teaser__button {
    @media screen and (max-width: 767px){
        margin-top: calc(12rem/16);
    }
}

.lunch-teaser__button button {
    @media screen and (max-width: 767px){
        font-size: calc(16rem/16);
    }
}

.lunch-teaser__day-info {
    color: var(--color-primary);
    display: block;

    @media screen and (min-width: 768px){
        display: unset;
    }
}

.lunch-teaser__img-icon {
    color: var(--color-primary);
    font-size: calc(18rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(34rem/16);
    height: calc(34rem/16);

    @media screen and (min-width: 768px){
        width: calc(38rem/16);
        height: calc(38rem/16);
        font-size: calc(20rem/16);
    }
}

.lunch-teaser__img-icon+.lunch-teaser__img-icon {
    border-left: 1px solid var(--color-lightgrey);
}

.lunch-teaser__img-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    background-color: #FFF;
}

.lunch-teaser__modal-body {
    padding: calc(60rem/16) calc(20rem/16) calc(20rem/16);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        padding: calc(80rem/16);
        font-size: calc(16rem/16);
    }
}

.lunch-teaser__modal-info .lunch-teaser__info-item{
    position: relative;
    padding-left:30px;
    display: block;
}
.lunch-teaser__modal-info .lunch-teaser__info-item .icon{
    position: absolute;
    top:5px;
    left:0;
}

.lunch-teaser__modal-info .lunch-teaser__info-item {
    margin: calc(5rem/16) 0;

    @media screen and (min-width: 768px){
        margin: calc(10rem/16) 0;
    }
}

.lunch-teaser__modal-info p{
    @media screen and (max-width: 767px){
        margin-bottom: calc(5rem/16);
        line-height: 22px;
    }
}

.lunch-teaser__modal-text {
    margin: calc(10rem/16) 0 calc(25rem/16);
}

.lunch-teaser__modal-dialog {
    position: relative;

    @media screen and (max-width: 767px){
        margin-top: 0.5rem;
    }

    @media screen and (min-width: 1200px){
        min-width: 850px;
    }
}

.lunch-teaser__modal-close {
    position: absolute;
    top: 16px;
    right: 16px;
    color: var(--color-primary);
    font-size: calc(18rem/16);

    @media screen and (min-width: 768px){
        top: 22px;
        right: 22px;
        font-size: calc(20rem/16);
    }
}

.lunch-teaser__modal-close:hover {
    color: var(--color-primary-dark);
}

.lunch-teaser__modal-close .icon-plus {
    transform: rotate(45deg);
}

.lunch-teaser__modal-date {
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    line-height: 28px;
    margin: calc(25rem/16) 0 calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(19rem/16);
        margin: calc(45rem/16) 0 calc(12rem/16);
    }
}

.lunch-teaser__content strong,
.lunch-teaser__modal-body strong{
    @media screen and (max-width: 767px){
        display: block;
    }
}