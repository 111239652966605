.wysiwyg table {
    text-align: left;
    width:100%;
    font-size:calc(16rem/16);
    line-height:calc(24/16);
    font-family:var(--font-default);
}
.wysiwyg table th {
    font-family:var(--font-default);
}
.table td,
.wysiwyg table td {
    font-size:calc(16rem/16);
    text-align:left;
}
.table td,
.wysiwyg table td, .table th:not(:first-of-type),
.wysiwyg table th:not(:first-of-type) {
    border-left: 1px solid var(--color-lightgrey);
}
.table-fixed {
    table-layout:fixed;
}
.table--first-row--grey thead th {
    background-color:var(--color-grey);
}
.table--first-row--bold thead th {
    font-family:var(--font-default-bold);
}
.table--first-col--grey tbody th, .table--first-col--grey thead th:first-of-type {
    font-weight:normal;
    background-color:var(--color-grey);
}
.table--first-col--bold tbody th, .table--first-col--grey thead th:first-of-type {
    font-family:var(--font-default-bold);
}