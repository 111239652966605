.simple-map {
    padding-bottom: calc(80rem/16);
    position:relative;
}

.simple-map-contact {
    position:absolute;
    bottom:0;
    background-color:#fff;
    padding:calc(20rem/16) calc(30rem/16);
    margin-bottom:calc(-80rem/16);
    @media screen and (max-width: 767px) {
        position:relative;
        margin-top: calc(-30rem/16);
    }
}

.simple-map-contact__title {
    font-family:var(--font-default-bold);
    font-size:calc(25rem/16);
    line-height:calc(35/25);
    color:var(--color-primary);
}

.simple-map-contact__details .icon {
    color:var(--color-primary);
}

.simple-map-contact__details a:hover {
    color:var(--color-primary);
}
.simple-map-contact__details .icon-location {
    font-size: calc(25rem/16);
}

.simple-map-contact__details .icon-mail {
    font-size: calc(14rem/16);
}

.simple-map-contact__details .icon-website {
    font-size: calc(19rem/16);
}

.simple-map-contact__details .icon-phone {
    font-size: calc(20rem/16);
}


.simple-map-contact__details .icon-clock {
    font-size: calc(21rem/16);
}

.simple-map-contact__detail{
    margin-top:calc(5rem/16);
}

.simple-map-contact__details {
    font-family: var(--font-default-light);
    font-size:1rem;
}