.main-nav-overlay {
    height:calc(720rem/16);
    font-size:calc(16rem/16);
    font-family:var(--font-default-light);
    line-height:calc(20/16);
    box-shadow: 0 20px 25px 0 rgba(0,0,0,0.2);
    z-index: 15;
    @media screen and (max-width: 991px) and (min-width: 768px) {
        font-size:calc(14rem/16);
    }
    @media screen and (max-width: 767px) {
        width: 85%;
        transform: translateX(100%) translateY(0);
        height:100%;
    }
}

.main-nav-overlay.overlay.is-open {
    z-index: 15;
}

.main-nav-overlay.navbar-overlay--layering-open {
    z-index: 16;

    @media screen and (max-width: 767px) {
        margin-top: 58px;
    }

    @media screen and (min-width: 768px) {
        margin-top: 78px;
        height: calc(45rem - 78px);
    }
}

.main-nav-overlay.navbar-overlay--layering-open .main-nav-overlay__content {
    padding-top: calc(5.625rem - 58px);

    @media screen and (min-width: 768px) {
        padding-top: calc(9.375rem - 78px);
    }
}

.navbar.navbar--layering-open {
    z-index: 15;
}

.main-nav-overlay__content {
    padding-top:calc(150rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(90rem/16);
    }
}

.main-nav-overlay__list-title {
    color:var(--color-primary-dark);
    font-family:var(--font-default-bold);
    font-size:calc(20rem/16);
    line-height:calc(26/20);
    margin-bottom:calc(18rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
        line-height:calc(20/16);
        margin-bottom:calc(32rem/16);
    }
}

.main-nav-overlay__list {
    padding-top:calc(10rem/16);
    @media screen and (max-width: 767px) {
        padding-left:calc(20rem/16);
        padding-top:calc(10rem/16);
        padding-bottom:calc(20rem/16);
        border-bottom: 1px solid var(--color-grey);
    }
}

.main-nav-overlay__list-item {
    padding-top:calc(5rem/16);
    padding-bottom:calc(5rem/16);
    @media screen and (max-width: 767px) {
       line-height:3;
        font-size: calc(14rem/16);
    }
}
.main-nav-overlay__list-item .active, .main-nav-overlay__list-title.active, .main-nav-overlay__additional-list-item .active {
    color:var(--color-primary);
    position:relative;
}
.main-nav-overlay__list-title.active:before {
    content:'';
    display:block;
    position:absolute;
    height:1px;
    width:100%;
    bottom: calc(-5rem/16);
    background-color:var(--color-primary);
}

.main-nav-overlay__additional-list {
    border-top:1px solid var(--color-primary);
    font-family:var(--font-default-bold);
    margin-top:calc(18rem/16);
    padding-top: calc(5rem/16);
}

.main-nav-overlay__additional-list-item {
    padding-top:calc(5rem/16);
    padding-bottom:calc(5rem/16);
    @media screen and (max-width: 767px) {
        line-height:3;
        font-size: calc(14rem/16);
        font-family:var(--font-default-bold);
    }
}

.main-nav-overlay__content a:hover {
    color:var(--color-primary);
}

.main-nav-overlay__list-container {
    @media screen and (max-width: 767px) {
        padding-bottom: calc(22rem / 16);
    }
}

.main-nav-overlay .accordion-icon--change {
    font-size:calc(12rem/16);
}