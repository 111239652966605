.breadcrumb {
    font-size:calc(13rem/16);
    display:flex;
    padding-top:calc(10rem/16);
    padding-bottom:calc(10rem/16);
    color:#D6D6D6;
    padding-left:0;
    @media screen and (max-width: 767px) {
        display: inline-block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }
}
.breadcrumb-item+.breadcrumb-item:before {
    color:#D6D6D6;
}
.breadcrumb-item {
    @media screen and (max-width: 767px) {
        display: inline;
    }
}