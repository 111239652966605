
.content-block {
    padding-top:calc(48rem/16);
    padding-bottom:calc(48rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(25rem/16);
        padding-bottom:calc(40rem/16);
    }
}

.content-block.bg-lightgrey {
    padding-bottom:calc(42rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom:calc(20rem/16);
    }
}
.content-block:not([class*="bg-"]) + .content-block:not([class*="bg-"]),
.content-block:not([class*="bg-"]) + .pimcore_area_content > .content-block:not([class*="bg-"]),
.content-block.bg-has-white-bottom + .content-block:not([class*="bg-"]),
.pimcore_area_content + .pimcore_area_content > .content-block:not([class*="bg-"]) {
    padding-top: calc(10rem / 16);
    @media screen and (min-width: 768px) {
        padding-top: calc(10rem / 16);
    }
}


.main-content-body > .content-block:first-of-type {
    padding-top:calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(30rem/16);
    }
}