.overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateY(-100%);
    transition: transform 450ms ease-in-out, visibility 0.001ms 450ms, opacity 450ms ease;
    background: white;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    z-index: -1;
    color: var(--color-text-default);
    @media screen and (max-width:767px) {
        padding-left:0;
        transition: transform 250ms ease-in-out, visibility 0.001ms 250ms, opacity 250ms ease;

    }
}

.overlay--mobile {
    height: auto;
    bottom: 53px;
    top: inherit;
    transform: translateY(100%);
    padding:calc(30rem/16);
    @media screen and (max-width:767px) {
        padding-left:calc(30rem/16);
    }
}

.overlay-container {
    position:relative;
    height:100%;
    padding-bottom:calc(50rem/16);
    @media screen and (max-width:767px) {
        padding-left: calc(40rem/16);
        padding-right: calc(40rem/16);
        position:relative;

    }
}

.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    //opacity: 1;
    z-index: 4;
    transition: transform 400ms ease-in-out, visibility 0.001ms, opacity 1ms ease;
    @media screen and (max-width: 767px) {
        z-index: 4;
        transition: transform 250ms ease-in-out, visibility 0.001ms, opacity 1ms ease;

    }
}

.overlay--left {
    left:0;
    width:calc(305rem/16);
    box-shadow: 0 20px 25px 0 rgba(0,0,0,0.2);
    transform: translateX(-100%) translateY(0);
}

.overlay--full-width {
    transform: translateX(0);
    transition: visibility 0.001ms 600ms, opacity 600ms ease;
}

.overlay--full-width .overlay__content {
    max-width: calc(1920rem/16);
    margin:0 auto;
}

.overlay--full-width.is-open {
    left:0;
    padding-left:0px;
    z-index: 999;
    overflow-y:scroll;
    transition: visibility 0.001ms, opacity 600ms ease;
}

.overlay__close {
    position:relative;
    float:right;
    right:20px;
    top:20px;
    background-color:#fff;
    width:calc(70rem/16);
    height:calc(70rem/16);
    z-index: 10;
    box-shadow:none;
    border:none;
    color:#000;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:calc(20rem/16);
    cursor:pointer;
    transition: all 0.2s ease;
    @media screen and (max-width:767px) {
        width:calc(50rem/16);
        height:calc(50rem/16);
        right:2px;
        top:2px;
    }
}

.overlay__close:hover {
    background-color:var(--color-primary-dark);
    color:#fff;
}

.overlay--mobile .overlay__close {
    background-color:transparent;
    color:var(--color-grey);
    left:initial;
    right:0;
}

.overlay__header {
    height: 60px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 500;
    display: flex;
    align-items: center;
    font-size: 17px;
    padding: 0 calc(20rem/16);
    font-family: var(--font-default-bold);
    flex: none;
}

.overlay__content {
    flex: auto;
    z-index: 1;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.overlay.filter-overlay.is-open {
    z-index: 10;
}

.filter-overlay {
    width:100%;
}