
.interactive-map-filter__wrapper {
    min-height:calc(638rem/16);
    display:flex;
    align-items:center;
    background-size: cover;
    background-repeat: no-repeat;
    @media screen and (max-width: 767px) {
        min-height:auto;
        padding: calc(30rem/16) 0px;
        background-size: cover;
    }
}

.interactive-map-filter {
    background-color:#fff;
    box-shadow: 0 20px 25px 0 rgba(0,0,0,0.2);
}

.interactive-map-filter__header {
    padding:calc(20rem/16) calc(10rem/16);
    text-align:center;
    background:var(--bg-primary-gradient);
    font-family:var(--font-default-bold);
    font-size:calc(25rem/16);
    line-height:calc(35/25);
    color:#fff;
}

.interactive-map-filter__body {
    padding: calc(55rem/16) calc(40rem/16);
    @media screen and (max-width: 767px) {
        padding: calc(30rem/16) calc(30rem/16);
    }

}

.interactive-map-filter__search input::-webkit-input-placeholder  {
    font-size:calc(18rem/16);
    font-family:var(--font-default);
    color:var(--color-text-default);
}

.interactive-map-filter__search input:-ms-input-placeholder {
    font-size:calc(18rem/16);
    font-family:var(--font-default);
    color:var(--color-text-default);

}

.interactive-map-filter__search input::-ms-input-placeholder {
    font-size:calc(18rem/16);
    font-family:var(--font-default);
    color:var(--color-text-default);
}

.interactive-map-filter__collapse-btn {

}