.video-js .vjs-big-play-button {
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    border-radius: 50%;
    width:calc(70rem/16);
    height:calc(70rem/16);
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-js .vjs-big-play-button {
    line-height: calc(65rem/16);
}
.vjs-controls-disabled .vjs-big-play-button, .vjs-error .vjs-big-play-button, .vjs-has-started .vjs-big-play-button, .vjs-using-native-controls .vjs-big-play-button {
    display:none;
}