
.link-list {
    display:flex;
    justify-content:center;
    font-size:calc(20rem/16);
    color:var(--color-primary);
    text-align:center;
    font-family:var(--font-default-bold);
    line-height:calc(24/20);
    margin-top:calc(20rem/16);
    margin-bottom:calc(20rem/16);
    @media screen and (max-width: 767px) {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        justify-content:flex-start;
    }
}
.link-list__item-name {
    padding-bottom:calc(7rem/16);
}
.link-list__item .active .link-list__item-name {
    border-bottom:2px solid var(--color-primary);
}