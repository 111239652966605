/*Margin Helper*/
.mb-40 { margin-bottom: calc(40rem/16);}
.mb-30 { margin-bottom: calc(30rem/16);}
.mb-md-30 { @media screen and (min-width: 768px) { margin-bottom: calc(30rem/16); }}
/*Padding Helper*/


/*Text Helper*/
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fz60 { font-size: calc(60rem/16); }
.fz22 { font-size: calc(22rem/16); }
.fz20 { font-size: calc(20rem/16); }
.fz18 { font-size: calc(18rem/16); }
.fz16 { font-size: calc(16rem/16); }
.fz14 { font-size: calc(14rem/16); }
.fz12 { font-size: calc(12rem/16); }

.lh-1 { line-height:1; }
/*Text Color Helper*/
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }

/*Background Color Helper*/
.bg-lightestgrey { background-color: var(--color-lightestgrey); }
.bg-lightgrey { background-color: var(--color-lightgrey); }
.bg-primary-light { background-color: var(--color-primary-light); }
.bg-dark { 	background: linear-gradient(45deg, #212121 0%, #4A4A4A 100%); }


.bg-has-white-bottom {
    position:relative;
    overflow:hidden;
}
.bg-has-white-bottom:before {
    content:'';
    display:block;
    position:absolute;
    height:calc(270rem/16);
    width:100%;
    bottom:0;
    background-color:#fff;
    z-index: 0;
    @media screen and (max-width: 767px) {
        height:calc(300rem/16);
    }
}
.bg-has-white-bottom .container {
    z-index: 1; }
.bg-primary-gradient {
    background: var(--bg-primary-gradient);
}
.isCursor { cursor:pointer; }


.font-bold { font-family:var(--font-default-bold); }
.font-default { font-family:var(--font-default); }
.font-light { font-family:var(--font-default-light); }

.no-margin {margin:0;}