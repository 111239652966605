.ui-datepicker {
    font-family: inherit;
    background: #ffffff;
    color: #222222;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.27);
    z-index: 9999 !important; /*override inline style*/
    font-size: 1rem;
    padding: .4em;
    display:none;

    @media screen and (max-width: 767px) {
        font-size: 1.2rem;
    }
}
.ui-datepicker table {
    font-size: 1em;
}
.ui-datepicker-title,
.ui-datepicker-calendar th{
    font-family: var(--font-default);
    font-weight: normal;
}
.ui-datepicker td a,
.ui-datepicker td span {
    text-align: center;
}
.ui-datepicker table .ui-state-hover,
.ui-datepicker table .ui-state-active {
    background: var(--color-primary);
    color: #ffffff;
}
.ui-datepicker .ui-state-disabled {
    opacity: .6;
}
.ui-datepicker-next,
.ui-datepicker-prev {
    text-align: center;
    position:absolute;
    line-height: 1.8em;
    cursor: pointer;
    font-family: 'iconfont';
    color:var(--color-primary);
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ui-datepicker-next {
    right:10px;
}
.ui-datepicker-prev {
    left:10px;
    transform:scale(-1,1);
}
.ui-datepicker-prev:before {
    content: var(--icon-link-arrow-right);
}
.ui-datepicker-next:after {
    content: var(--icon-link-arrow-right);
    transform: scale(-1, 1);
}
.ui-datepicker td a,
.ui-datepicker td span {
    padding: .4em;
}

.ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
}


.ui-datepicker-div {
    display:none;
}