.intro-slider__item {
    position: relative;
}

.intro-slider__content {
    padding: calc(88rem / 16) calc(82rem / 16) calc(20rem / 16) calc(82rem / 16);

    @media screen and (min-width: 768px) and (max-width: 1240px) {
        padding: calc(78rem / 16) calc(72rem / 16) calc(20rem / 16) calc(82rem / 16);
    }

    @media screen and (max-width: 767px) {
        padding: calc(20rem / 16);
        text-align:center;
    }
}

.intro-slider__headline {
    font-size: calc(50rem / 16);
    font-family: var(--font-default-bold);
    line-height: calc(57 / 50);
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
        font-size: calc(38rem / 16);
    }
    @media screen and (max-width: 1199px) {
        font-size: calc(22rem / 16);
    }
}

.intro-slider__content-detail {
    padding-top: calc(33rem / 16);
    padding-left: calc(164rem / 16);

    @media screen and (min-width: 768px) and (max-width: 1600px) {
        padding-top: calc(10rem / 16);
        padding-left: calc(144rem / 16);
        font-size: calc(14rem / 16);
    }

    @media screen and (max-width: 767px) {
            padding: 0;
    }
}

.intro-slider__content-detail > .wysiwyg {
    line-height: calc(24rem /14);

    @media screen and (max-width: 1199px) {
        display: none;
    }
    @media screen and (max-width: 1350px) {
        font-size: calc(12rem / 16);
        line-height: calc(25 / 12);
    }

}

.intro-slider__controls-wrapper {
    font-size: calc(14rem / 16);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    @media screen and (max-width: 767px) {
        position:relative;
    }
}

.intro-slider__img-author {
    border-radius: 50%;
    border: 5px solid #FFF;
    bottom:calc(-20rem/16);
    width: calc(218rem / 16);
    height: calc(218rem / 16);
    @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: calc(170rem / 16);
        height: calc(170rem / 16);
    }
    @media screen and (max-width: 767px) {
        width: calc(90rem / 16);
        height: calc(90rem / 16);
        border: 3px solid #FFF;
        position:absolute;
        left:0;
        right:0;
        bottom:calc(50rem/16);
    }
}

.intro-slider__img-author > img {
    border-radius: 50%;
}

.intro-slider__controls {
    z-index: 2;
    position: relative;
}

.intro-slider__arrow {
    transition: background.8s ease;

    background-color: #FFF;
    color: var(--color-primary);
    font-size: calc(16rem / 16);
    display: flex;

    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.intro-slider__arrow:hover {
    background: var(--color-primary-light);
    color: #FFF;
}

.intro-slider__arrow.slick-disabled {
    color: var(--color-grey);
}

.intro-slider__btn-wrapper {
    padding-top: calc(41rem / 16);

    @media screen and (max-width: 1600px) {
        padding-top: calc(10rem / 16);
    }
}

.intro-slider .slick-dots {
    display: none !important;
    visibility: hidden;
}

.intro-slider__animated-img {
    @media screen and (min-width: 768px) {
       // transform: scale(1.1);
    }
}

.intro-slider__animated .slider-content__text {
    @media screen and (min-width: 768px) {
        opacity: 0;
        transform: translateX(100%);

    }
}
.slick-current .intro-slider__animated-img {
    @media screen and (min-width: 768px) {
        transition: transform 6s ease-out 1s,
                    opacity ease-out .5s;
        opacity: 1;
        transform: scale(1);
    }
}

.slick-current .slider-content__text {
    @media screen and (min-width: 768px) {
        transition: all ease-out 1s;
        transform: translateX(0);
        opacity: 1;
    }
}


