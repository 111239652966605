.copyright {
    position: absolute;
    z-index: 2;
    color: #ffffff;
    font-size:calc(12rem/16);
    padding:calc(12rem/16);
    line-height:1;
    font-weight: 300;
    text-shadow: 0 0 8px rgba(0,0,0,.9);
    @media screen and (max-width: 767px) {
        font-size:calc(10rem/16);
        padding:calc(5rem/16);
    }
}
.copyright--top-left {
    top:0;
    left:0;
}
.copyright--top-right {
    top:0;
    right:0;
}
.copyright--bottom-left {
    bottom:0;
    left:0;
}
.copyright--bottom-right {
    bottom:0;
    right:0;
}
.copyright--bottom-center {
    left:0;
    right:0;
    bottom:0;
    text-align: center;
}