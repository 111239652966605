.img-slide {
    position: relative;
}

.img-slide:before {
    background: linear-gradient(180deg,rgba(57,57,57,.5),rgba(57,57,57,0));
    content: "";
    display: block;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.img-slide__content {
    z-index: 2;
    position: absolute;
    top:0;
    left:0;
    right:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
}

.img-slide__title {
    font-size: calc(50rem/16);
    line-height:calc(57/50);
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:calc(25rem/16);
    }
}
