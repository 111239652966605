.password-result {
    background: #fff;
    height:20px;
    margin-top:.25rem;
    border-radius:99px;
    overflow: hidden;
    border:1px solid transparent;
    transition: all .2s linear;
}
.password-result__bar {
    background: #ccc;
    height:100%;
    border-radius:99px;
    transition: all .2s linear;
}
.password-result.pwd-green .password-result__bar {
    width: 100%;
    background: #33CE88;
}
.password-result.pwd-orange {
    border-color:orange;
}
.password-result.pwd-orange .password-result__bar {
    width: 66%;
    background:orange;
}
.password-result.pwd-short,
.password-result.pwd-red {
    border-color:red;
}
.password-result.pwd-red .password-result__bar {
    width: 20%;
    background: red;
}
.password-result.pwd-short .password-result__bar {
    width: 0;
}