.press-text-list__item {
    border-bottom:1px solid var(--color-grey);
    padding-bottom:calc(20rem/16);
    margin-bottom:calc(20rem/16);
    transition: all 0.2s ease;
}
.press-text-list__item:hover {
    border-bottom:1px solid var(--color-primary-light);
}
.press-text-list__item:hover .press-text-list__title {
    color:var(--color-primary-light);
}
.press-text-list__title {
    font-size:calc(22rem/16);
    font-family:var(--font-default-bold);
    transition: all 0.2s ease;
}
.press-text-list__description {
    font-size:calc(16rem/16);
    line-height:1.4;
    @media screen and (max-width:767px) {
        font-size:calc(14rem/16);
    }
}