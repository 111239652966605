.filter-search {
    position: relative;
    width: calc(250rem / 16);

    @media screen and (max-width: 767px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
    }
}

.filter-search__input {
    height: calc(43rem / 16);
    width: calc(183rem / 16);
    padding: calc(11rem / 16) calc(40rem / 16) calc(11rem / 16) calc(53rem / 16);
    color: var(--color-text-default);
    border: 1px solid var(--color-primary);
    border-radius: calc(21.5rem / 16);
    transition: width ease .5s, padding ease .8s;

    @media screen and (max-width: 768px) {
        padding: calc(11rem / 16) calc(40rem / 16);
        width: 100%;
    }
}

.filter-search__input::-ms-clear {
    display: none;
}
.filter-search__input:focus {
    color: var(--color-primary);
    box-shadow: none;

    @media screen and (min-width: 768px) {
        width: 100%;
        padding-left: calc(43rem / 16);
    }
}

.filter-search__input:focus ~
.filter-search__icon {

    @media screen and (min-width: 768px) {
        transform: translate(-10px, -50%);
    }
}

.filter-search__input:focus ~
.filter-search__close {

    @media screen and (min-width: 768px) {
        transform: translate(70px, -50%);
    }
}

.filter-search__icon {
    margin-right: calc(6rem / 16);
    color: var(--color-primary);
    position: absolute;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    left: calc(31rem / 16);
    transition: transform ease .5s;

    @media screen and (max-width: 767px) {
        left: calc(15rem / 16);
    }
}

.filter-search__close {
    position: absolute;
    top: 50%;
    right: calc(73rem /16);
    cursor: pointer;
    padding: calc(10rem/16);
    transform: translateY(-50%);
    transition: transform ease .5s;
    font-size: calc(14rem / 16);

    @media screen and (max-width: 767px) {
        right: calc(20rem /16);
    }
}

@media screen and (min-width: 768px) {
    .filter-search__close:hover {
        color: var(--color-primary);
    }
}


