.bg-filter {
    padding-bottom: 190px;
    padding-top:4rem;
    background-repeat: no-repeat;
    background-position: 50% 0;

    @media screen and (min-width: 768px) {
        padding-top:7.25rem;
        background-position: 50% 0;
    }

    @media screen and (min-width: 1200px) {
        padding-top:9.5rem;
    }
}

.bg-filter__inner {
    padding-top:calc(30rem/16);
    color: #FFF;
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
    }
}

.bg-filter__title{
    font-family: var(--font-default-bold);
    letter-spacing: 1px;
    margin-top: calc(30rem/16);
    margin-bottom: calc(5rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(40rem/16);
    }
}

.bg-filter__checkbox__label{
    border-radius: 100px;
    background: transparent;
    border: 1px solid rgba(242, 242, 242, 0.5);
    padding: calc(5rem/16)  calc(18rem/16);
    cursor: pointer;
    user-select: none;

    @media screen and (min-width: 768px) {
        padding: calc(5rem/16)  calc(30rem/16);
    }
}

.bg-filter__checkbox__input:checked ~ .bg-filter__checkbox__label {
    background: var(--bg-primary-gradient);
    border: none;
    padding: calc(6rem/16)  calc(19rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(6rem/16)  calc(31rem/16);
    }
}

.bg-filter__checkbox__input:not(:checked) ~.bg-filter__checkbox__label:hover{
    @media (hover: hover) {
        background: var(--bg-primary-gradient-transparent);
        border: none;
        padding: calc(6rem/16)  calc(31rem/16);
    }
}


.bg-filter__checkbox__input{
    display: none;
}

.bg-filter__checkbox{
    margin: calc(5rem/16) calc(5rem/16);

    @media screen and (min-width: 768px){
        margin: calc(5rem/16) calc(8rem/16);
    }
}

.bg-filter__plus-button {
    position: relative;
    border-radius: 50%;
    width: calc(22rem/16);
    height: calc(22rem/16);
    line-height: calc(23rem/16);
    border: 1px solid rgba(242, 242, 242, 0.5);
    margin-left: calc(10rem/16);
    display: inline-block;
    transform: rotate(45deg);
    transition: transform 0.2s;
}
.bg-filter__plus-button .icon {
    font-size: calc(8rem/16);
    width: calc(8rem/16);
    height: calc(8rem/16);
    line-height: calc(8rem/16);
    position: absolute;
    left:50%;
    top:50%;
    transform: translateY(-50%) translateX(-50%);
}

.bg-filter__plus-button:hover{
    padding: calc(1rem/16);
    border: none;
    background: var(--bg-primary-gradient);
}

.bg-filter__plus-button.collapsed{
    transform: rotate(0deg);
}

.bg-filter__search{
    border: 1px solid rgba(242, 242, 242, 0.5);
    border-radius: 100px;
    background: transparent;
    color: #FFF;
    padding: calc(6rem/16) calc(18rem/16);
    width: 100%;

    @media screen and (min-width: 768px) {
        width: calc(400rem/16);
        padding: calc(6.5rem/16) calc(31rem/16);
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}

.bg-filter__search::placeholder{
    color: #FFF;
}

.bg-filter__search:focus{
    outline: none;
}

.bg-filter__buttons{
    margin-top: calc(45rem/16);
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.bg-filter__region,
.bg-filter__features{
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(-5rem/16);
    @media screen and (min-width: 768px){
        margin: 0 calc(-8rem/16);
    }
}

.bg-filter__region.collapsing,
.bg-filter__features.collapsing{
    transition: height 0.2s;

    @media screen and (min-width: 768px){
        transition: height 0.1s;
    }
}

.container.bg-filter__days-container{
    @media screen and (max-width: 767px){
        padding: 0;
    }
}

.container.bg-filter__days-container .lunch-tabs{
    @media screen and (max-width: 767px){
        padding-left: 15px;
    }
}

.lunch-tabs li:last-of-type .lunch-tab-item {
    @media screen and (max-width: 767px){
        margin-right: 15px;
    }
}