
.content-video {
    max-width: calc(950rem/16);
    margin:0 auto;
    position:relative;
}
.content-video:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.6s ease;
    background: linear-gradient(180deg, rgba(0,0,0,-0.01) 0%, rgba(0,0,0,0) 0%, #000000 100%);
    top: 0;
    left: 0;
    z-index: 1;
}
.content-video__text-overlay {
    padding:calc(40rem/16);
    position:absolute;
    left:0;
    right:0;
    width:100%;
    height:100%;
    top:0;
    display: flex;
    align-items:flex-end;
    @media screen and (max-width: 767px) {
        display:flex;
        justify-content:center;
        align-items:center;
    }
}
.content-video__title {
    color:var(--color-primary-light);
    font-size:calc(25rem/16);
    font-family:var(--font-default-bold);
    line-height:calc(35/25);
    text-align:center;
    max-width:calc(550rem/16);
    margin:0 auto;
    z-index: 3;
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }
}
.content-video__play {
    font-size:calc(90rem/16);
    color:#fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: 1;
    @media screen and (max-width: 767px) {
        font-size:calc(30rem/16);
        position:relative;
    }
}

.content-video__play .icon {
    @media screen and (max-width: 767px) {
        position:absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.content-video:hover:before {
    background-color: rgba(128,183,203,.4);
}
.content-video .video-thumb {
    transition: all 0.5s ease;
}
.content-video:hover .video-thumb {
    transform: scale(1.03);
}
