.interactive-map {
    position: relative;
    overflow: hidden;
}

.interactive-map__button-wrapper {
    @media screen and (max-width: 767px) {
        width: 100%;
        top: 0;
        z-index: 12;
        height: calc(40rem / 16);
    }
}

.interactive-map__button {
    height: calc(61rem / 16);
    width: calc(50rem / 16);
    background: var(--bg-primary-gradient);
    color: #fff;
    box-shadow: none;
    position: absolute;
    top: 0;
    z-index: 2;
    outline: 0;
    border: 0;
    cursor: pointer;
    font-size: calc(20rem / 16);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        position: relative;
        width: 100%;
        font-size: calc(16rem / 16);
        line-height: calc(18 / 16);
        padding: 0 calc(15rem / 16);
        height: calc(40rem / 16);
    }
}

.interactive-map__button .icon-dropdown {
    @media screen and (max-width: 767px) {
        font-size: calc(9rem / 16);
        transition: scale 0.2s ease;
    }
}

.interactive-map__button.active .icon-dropdown {
    @media screen and (max-width: 767px) {
        transform: scale(1, -1);
    }
}

@media screen and (min-width: 768px) {
    .interactive-map__button {
        transition: transform 400ms ease-in-out, visibility 0.001ms, opacity 1ms ease;
    }

    .interactive-map__button.active {
        transition: transform 400ms ease-in-out, visibility 0.001ms 400ms, opacity 400ms ease;
        transform: translateX(305px);
    }

    .interactive-map__button .icon {
        transition: scale 0.2s ease;
    }

    .interactive-map__button.active .icon {
        transform: scale(-1, 1);
    }
}

.interactive-map__zoom-btn {
    width: calc(45rem / 16);
    height: calc(45rem / 16);
    background-color: #fff;
    font-size: calc(16rem / 16);
    position: absolute;
    bottom: calc(40rem / 16);
    right: calc(40rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding:0;
    border-radius: 3px;

    @media screen and (max-width: 767px) {
        width: calc(35rem / 16);
        height: calc(35rem / 16);
        bottom: calc(10rem / 16);
        right: calc(10rem / 16);
        padding: calc(10rem / 16);
        border-radius: 3px;
        font-size: calc(13rem / 16);
    }
}

.interactive-map__zoom-btn + .interactive-map__zoom-btn {
    bottom: calc(90rem / 16);
    @media screen and (max-width: 767px) {
        bottom: calc(50rem / 16);
    }
}

.interactive-map.is-fullscreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.interactive-map.is-fullscreen .interactive-map__button-wrapper {
    position:relative;

}
.interactive-map.is-fullscreen .filter-form-map {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
}