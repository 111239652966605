
.event-teaser {
    position:relative;
}

.event-teaser .embed-responsive:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(146,209,232,0);
    transition: all .3s ease;
    z-index: 0;
}

.event-teaser:hover .embed-responsive:after {
    background-color: rgba(128,183,203,.4);
}

.event-teaser__body {
    padding: calc(15rem/16) 0;
}
.event-teaser__title {
    font-size:calc(22rem/16);
    font-family:var(--font-default-bold);
    line-height:calc(28/22);
    width: calc(100% - 100px);
    @media screen and (min-width: 768px) and (max-width:1199px) {
        width:100%;
        margin-top:calc(30rem/16);
    }
}

.event-teaser__text {
    padding:calc(6rem/16) 0;
    font-family:var(--font-default-light);
    font-size:calc(16rem/16);
}

.event-teaser__location {
    font-family:var(--font-default-bold);
    padding-top:calc(7rem/16);
}


.event-teaser__date {
    background: var(--bg-primary-gradient);
    height: calc(71rem/16);
    width: calc(97rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    position: absolute;
    bottom: 0;
    z-index: 1;
    right: 0;
}

.event-teaser__date--day {
    color:#fff;
    text-align:center;
    font-size:calc(26rem/16);
    font-family:var(--font-default-bold);
    display:block;
    line-height:1;

}

.event-teaser__date--month {
    font-size:calc(14rem/16);
    font-family:var(--font-default-light);
    color:#fff;
    text-align:center;
    line-height:calc(16/14);
}

.event-teaser__category {
    height:calc(33rem/16);
    width:calc(97rem/16);
    background-color:var(--color-secondary);
    color:#fff;
    font-size:calc(14rem/16);
    line-height:calc(16/14);
    text-align:center;
    position:absolute;
    right:0;
    display:flex;
    justify-content: center;
    align-items:center;
}