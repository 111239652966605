

.dropdown {
    overflow:visible;
}
.dropdown-menu {
    width:100%;
}

.dropdown.form-box .dropdown-toggle:after {
    display:none;
}

.dropdown .form-box__content {
    cursor:pointer;
}