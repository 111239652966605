.lunch-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
}

.lunch-tab-content{
    margin-top: -125px;
    padding-top: 0!important;
}
.lunch-tab-content--with-results{
    margin-top: -160px;
}

.lunch-tab-item__li+.lunch-tab-item__li {
    margin-left: calc(12rem/16);

    @media screen and (min-width: 768px){
        margin-left: calc(25rem/16);
    }
}

.lunch-tab-item{
    border-radius: calc(10rem/16);
    border: 1px solid rgba(242, 242, 242, 0.5);
    font-size: calc(12rem/16);
    line-height: 1;
    color: #FFF;
    margin-bottom:.625rem;
    width: calc(76rem/16);
    height: calc(76rem/16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        width: calc(92rem/16);
        height: calc(92rem/16);
        margin-bottom:0;
    }
}

.lunch-tab-item.active {
    background: var(--bg-primary-gradient);
    border: none;
}

.lunch-tab-item:hover:not(.active){
    background: var(--bg-primary-gradient-transparent);
    border: none;
}

.lunch-tab-item__date{
    font-size: calc(25rem/16);
    font-family: var(--font-default-bold);
    margin: calc(3rem/16) 0 calc(3rem/16);

    @media screen and (min-width: 768px) {
        font-size: calc(30rem/16);
        margin: calc(4rem/16) 0;
    }
}

.lunch-tab-item--next{
    font-size: calc(14rem/16);
    line-height: 1.3;
    text-align: center;
}

.lunch-tab-item--next .icon{
    font-size: calc(12rem/16);
    margin-top: calc(7rem/16);
}