
.menu-icon__wrapper {
    position:relative;
    width:calc(65rem/16);
    height:calc(28rem/16);
}

.menu-icon {
    position: absolute;
    width: calc(65rem/16);
    height: calc(22rem/16);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.menu-icon__line {
    position: absolute;
    right: 0;
    background: var(--bg-primary-gradient);
    height: calc(2rem/16);
    width: calc(48rem/16);
    pointer-events: none;
    transition: all 0.2s ease;
}
.navbar-white .menu-icon__line {
    background-color: var(--color-text-default);
}

.menu-icon__desc {
    font-size:calc(11rem/16);
    position: absolute;
    bottom: calc(-20rem/16);
    right: 0;
    color:var(--color-primary);
    transform: translateX(0px);
    transition: transform 0.2s ease;
}
.is-main-nav-open .menu-icon__desc {
    transform: translateX(-10px);
}
.menu-icon__line-1 {
    width: calc(65rem/16);
}

.menu-icon__line-1 {
    top: 0;
}

.menu-icon__line-2 {
    top: 10px;
    margin: auto;
}

.menu-icon__line-3 {
    top: 20px;
    bottom: 0;
    margin: auto;
}

.is-main-nav-open .menu-icon__line-1 {
    opacity:0;
}

.is-main-nav-open .menu-icon__line-2 {
    transform: rotate(25deg) translateX(12px);
    width: 55px;
    right:calc(8rem/16);
}
.is-main-nav-open .menu-icon__line-3 {
    transform: rotate( -25deg) translateX(11px) translateY(0px);
    width: 55px;
    right:calc(8rem/16);

}