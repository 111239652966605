.text-with-img__img {
    max-width: calc(691rem / 16);
    width: 100%;
    padding-bottom: calc(30rem / 16);
    padding-top: calc(10rem / 16);
}
.text-with-img__img.text-with-img__img--portrait {
    max-width: calc(391rem / 16);

    @media screen and (max-width: 767px) {
        max-width: calc(231rem / 16);
        margin: 0 auto;
    }
}

.text-with-img--left .text-with-img__img {

    @media screen and (min-width: 768px) {
        float: left;
        padding-right: calc(60rem / 16);
    }
}

.text-with-img--right .text-with-img__img {
    @media screen and (min-width: 768px) {
        padding-left: calc(60rem / 16);
        float: right;
    }
}

.text-with-img__content .wysiwyg {
    display: inline;
}

.text-with-img__img-caption {
    position: absolute;
    bottom: -30px;
    background-color: #FFFFFF;
    padding: calc(7rem / 16) calc(19rem / 16);
    font-size: calc(14rem / 16);
    max-width:85%;
    font-family: var(--font-default-light);
}

.text-with-img__img-caption-title {
    font-size: calc(16rem / 16);
    color: var(--color-primary);
    font-family: var(--font-default-bold);
    padding-top: calc(7rem / 16);
    line-height:1;
}

.text-with-img--right .text-with-img__img-caption {
    right: 0;
}

.text-with-img--right .img-zoom__icon {
    left: 0;
    right: initial;
    padding-left: 1.5625rem;
    @media screen and (max-width: 767px) {
        padding-left: calc(10rem/16);
    }
}