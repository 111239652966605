
.filter-checkbox {
    position: relative;
    cursor: pointer;
}

.filter-checkbox label {
    cursor: pointer;
}

.filter-checkbox__icon {
    font-size: calc(50rem / 16);
    text-align: center;
    color: var(--color-primary);
    display: block;
    padding-bottom: calc(10rem / 16);
    transition: all 0.2s ease;
    @media screen and (max-width: 767px) {
        font-size: calc(35rem / 16);
    }
}

.filter-checkbox:hover .filter-checkbox__icon {
    transform: translateY(-4px);
}

.filter-checkbox:before,
.filter-checkbox:after {
    content: " ";
    display: table;
}

.filter-checkbox:after {
    clear: both;
}

.filter-checkbox__input {
    position: absolute;
    width: calc(1rem / 16);
    height: calc(1rem / 16);
    margin: calc(-1rem / 16);
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.filter-checkbox__box {
    position: absolute;
    right: 33%;
    bottom: 25px;
    overflow: hidden;
    background: var(--bg-primary-gradient);
    width: calc(26rem / 16);
    height: calc(26rem / 16);
    border-radius: 50%;
    margin-top: .1em;
    @media screen and (max-width: 767px) {
        right: 14%;
        bottom: 16px;
    }
}

.filter-checkbox__input:checked ~ .filter-checkbox__box:after {
    display: none;
}

.filter-checkbox__box:before {
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
    font-size: 13px;
    color: #fff;
    opacity: 0;
    transition: opacity 0.1s ease;
}

.filter-checkbox__box:after {
    position: absolute;
    content: '';
    display: block;
    width: calc(21rem / 16);
    height: calc(21rem / 16);
    right: 0;
    background-color: #fff;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: all 0.2s ease;
}

.filter-checkbox:hover .filter-checkbox__box:after {
    transform: translateX(-50%) translateY(-50%) scale(0.9);
}

.filter-checkbox__input:checked ~ .filter-checkbox__box:before {
    opacity: 1;
}

.has-error .filter-checkbox__box {
    border-color: var(--color-danger)
}

.filter-checkbox__text {
    display: block;
    overflow: hidden;
    font-size: calc(18rem / 16);
    font-family: var(--font-default);
    line-height: calc(21 / 18);
    text-align: center;
    position: relative;

    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}

.filter-checkbox__icon:after {
    content: "";
    position: absolute;
    width: 30px;
    height: 3px;
    bottom: 0;
    right: 0;
    margin: 0 auto;
    left: 0;
    background: var(--bg-primary-gradient);
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
    transform: scaleX(0);
}

.filter-checkbox:hover .filter-checkbox__icon:after {
    visibility: visible;
    transform: scaleX(1);
}