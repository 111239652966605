.arrow-link__wrapper {
    display: flex;
    align-items: center;
}
.arrow-link__wrapper:hover {
    color:var(--color-primary);
}
.arrow-link__wrapper:hover .arrow-link__text {
    opacity:1;
}
.arrow-link__text {
    opacity:0;
    transition: all 0.3s ease;
    font-size:calc(11rem/16);
    color:var(--color-primary);
}