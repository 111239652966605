
.icon-link-list {
    padding-top:calc(40rem/16);
    padding-bottom:calc(16rem/16);

    @media screen and (max-width: 768px) {
        overflow-x: scroll;
        padding-top:calc(20rem/16);
        padding-bottom:calc(20rem/16);
    }
}

.icon-link-list__item {
    text-align:center;
}

.icon-link-list__item-icon {
    color:var(--color-primary);
    font-size:calc(60rem/16);
    transform: scale(1) translateY(0px);
    transition: all 0.3s ease;

    @media screen and (max-width: 768px) {
        font-size:calc(30rem/16);
    }
}

.icon-link-list__item-name {
    font-family:var(--font-default-bold);
    font-size:calc(20rem/16);
    line-height:calc(24/20);
    margin-top:calc(10rem/16);
    transform: translateY(0px);
    transition: all 0.3s ease;

    @media screen and (max-width: 768px) {
        font-size:calc(15rem/16);
    }
}

.icon-link-list__small .icon-link-list__item-name {
    font-size: 16px;
}

.icon-link-list__item:hover .icon-link-list__item-icon {
    color:var(--color-primary-dark);
    transform: scale(1.03) translateY(5px);
}

.icon-link-list__item:hover .icon-link-list__item-name {
    transform: translateY(-5px);
}