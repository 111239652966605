

.slide__item {
    position:relative;
}


.slick-dots {
    text-align:center;
    margin-top:calc(30rem/16);
    padding-left:0px;
    @media screen and (max-width: 767px) {
        margin-top:calc(10rem/16);
    }
}

.slick-dots button:before {
    display:block;
    border-radius:50%;
    box-shadow:none;
    content:'';
    background: var(--bg-primary-gradient);
    height:20px;
    width:20px;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

.slick-dots li:not(.slick-active) button:after {
    border-radius:50%;
    box-shadow:none;
    content:'';
    background: #fff;
    height:16px;
    width:16px;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    z-index: 2;
    transition: all 0.2s ease;

}

.slick-dots button:hover:after {
    transform:scale(0.01);
}
.slick-dots li {
    display:inline-block;
    padding-left:calc(7rem/16);
    padding-right:calc(7rem/16);
}

.slick-dots {
    list-style: none;
}

.slick-dots button {
    font-size: 0;
    line-height: 0;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    list-style: none;
    position:relative;
    display:flex;
    justify-content:center;
    align-items:centeR;
}