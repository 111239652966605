

.content-border-padding {
    padding-top:calc(60rem/16);
    padding-bottom:calc(50rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
        padding-bottom:calc(20rem/16);
    }
}


.content-border-padding--sm {
    padding-top:calc(48rem/16);
    padding-bottom:calc(48rem/16);
    @media screen and (max-width: 767px) {
        padding-top:calc(20rem/16);
        padding-bottom:calc(20rem/16);
    }
}

