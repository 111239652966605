.point-divider-before {
    padding-left:calc(10rem/16);
    position:relative;
}
.point-divider-before:before {
    content:'·';
    position:absolute;
    left:0
}
.point-divider-after {
    padding-right:calc(10rem/16);
    position:relative;

}
.point-divider-after:after {
    content:'·';
    position:absolute;
    right:0;
    top:0;
    @media screen and (max-width: 767px) {
        position: absolute;
        right:initial;
        margin-left: 6px;
    }
}