
.embed-responsive-12by5 {
    padding-bottom: calc(5 / 12 * 100%);
}

.embed-responsive-19by27 {
    padding-bottom: calc(27 / 19 * 100%);
}

.embed-responsive-15by17 {
    padding-bottom: calc(17 / 15 * 100%);
}

.embed-responsive-14by5 {
    padding-bottom: calc(5 / 14 * 100%);
}

.embed-responsive-31by22 {
    padding-bottom: calc(22 / 31 * 100%);

}

.embed-responsive-27by16 {
    padding-bottom: calc(16 / 27 * 100%);

}
.embed-responsive-28by41 {
    padding-bottom: calc(41 / 28 * 100%);

}

.embed-responsive-47by33 {
    padding-bottom: calc(33 / 47 * 100%);

}

.embed-responsive-img-gallery {
    padding-bottom: calc(64 / 95 * 100%);
}

.embed-responsive-intro-slider {
    padding-bottom: calc(92 / 96 * 100%);
}

.embed-responsive-intro-slider__mobile {
    padding-bottom: calc(26 / 38 * 100%);
}

.embed-responsive-65by43 {
    padding-bottom: calc(43 / 65 * 100%);
}

.embed-responsive-2by1 {
    padding-bottom: calc(1 / 2 * 100%);
}

.embed-responsive-3by2 {
    padding-bottom: calc(2 / 3 * 100%);
}

.embed-responsive-3by1 {
    padding-bottom: calc(1 / 3 * 100%);
}

.embed-responsive-16by23 {
    padding-bottom: calc(23 / 16 * 100%);
}

.embed-responsive-9by5 {
    padding-bottom: calc(5 / 9 * 100%);
}

.embed-responsive-7by5 {
    padding-bottom: calc(5 / 7 * 100%);

}

.embed-responsive-hero-existing-blog {
    padding-bottom: calc(745 / 1440 * 100%);
}

.embed-responsive-19by8 {
    padding-bottom: calc(8 / 19 * 100%);
}


.embed-responsive-cover {
    height: 100%;
}

.embed-responsive-cover .embed-responsive-item {
    width: auto;
    min-width: 100%;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}
