.loading-overlay-container {
    position: relative;
    overflow: hidden;
}

.loading-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, .7);
    z-index: 10;
    text-align: center;
}
.loading-overlay--xs-fixed{
    @media screen and (max-width: 767px){
        position:fixed;
        top:50%;
        bottom:auto;
        left:15px;
        right:15px;
        transform: translateY(-50%);
        border-radius: .625rem;
        padding-bottom:15px;
    }
}
.loading-overlay--dark {
    color:#fff;
    background: rgba(0, 0, 0, .7);
}
.loading-overlay-map {
    z-index:1;
    background: rgba(255, 255, 255, 0.8);
}