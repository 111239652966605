

.page-link .icon {
    font-size:calc(15rem/16);
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        margin:0;
    }
}

.page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
    font-size:calc(20rem/16);
    font-family:var(--font-default-light);
    line-height:calc(24/20);
    color:var(--color-text-default);

}

.page-item.active .page-link {
    color:var(--color-text-default);
    font-family:var(--font-default-bold);
}

.page-link__next, .page-link__prev {
    text-transform: uppercase;
    font-family:var(--font-default);
}

.page-link {
    @media screen and (max-width: 767px) {
        padding:calc(5rem/16) calc(10rem/16);
    }
}