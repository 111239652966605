.loading-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    text-align: center;
}
.loading-spinner__item {
    width: 18px;
    height: 18px;
    background-color: var(--color-primary);

    border-radius: 100%;
    display: inline-block;
    animation: loading-spinner-rotate 1.4s infinite ease-in-out both;
}
.loading-spinner__item--1 {
    animation-delay: -0.32s;
}
.loading-spinner__item--2 {
    animation-delay: -0.16s;
}
@keyframes loading-spinner-rotate {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}


.loading-spinner-map {
    //animation: spin 1.2s ease-in-out infinite;
    //border: 2px solid transparent;
    //border-top-color: var(--color-primary-light);
    border-radius: 100%;
    //filter: drop-shadow(0 0 1px var(--color-primary) );
    height: calc(130rem/16);
    margin: 0 auto;
    position: relative;
    width:  calc(130rem/16);
}

.loading-spinner__map-item {
    animation: spin 1s ease-in-out infinite;
    border: 2px solid transparent;
    border-radius: 100%;
    bottom: 1px;
    left:  1px;
    position: absolute;
    right:  1px;
    top:  1px;
}
.loading-spinner__map-item--1 {
    border-top-color: var(--color-primary-dark);
    transform: rotate(120deg);
}
.loading-spinner__map-item--2 {
    animation-duration: 2s;
    border-top-color:var(--color-primary-light);
    transform: rotate(240deg);
}
.loading-spinner__map-item-icon {
    font-size:calc(50rem/16);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color:var(--color-primary);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    50% {
        box-shadow: 0 -2px 4px rgba(#fff, .2);
        opacity: .7;
    }

    100% {
        transform: rotate(360deg);
    }
}