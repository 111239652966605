
.modal-dialog {
    max-width:calc(700rem/16);
    margin-top:10%;
    @media screen and (max-width: 767px) {
        max-width:100%;

    }
}

.modal-header {
    background:var(--bg-primary-gradient);
    color:#fff;
    text-align:center;
    position:relative;
    padding-top:calc(13rem/16);
    padding-bottom:calc(13rem/16);
}

.modal-title {
    font-size:calc(22rem/16);
    font-family:var(--font-default-bold);
    line-height:calc(35/22);
    width: calc(100% - 64px);
    margin:0;
}

.modal-header .modal-close {
    position:absolute;
    right:0;
    top:0;
    height:100%;
    color:#fff;
    font-size: calc(10rem/16);
    margin:0;
    opacity:1;
    border-left:1px solid #fff;
    width:calc(64rem/16);
}

.modal-header .modal-close.close:hover {
    background-color:#fff;
    color:var(--color-primary);
    opacity:1;
}

.modal-close .icon {
    font-size: calc(17rem/16);
    padding-right:0px;
}

.modal-body {
    padding-top:calc(60rem/16);
    padding-bottom:calc(60rem/16);
}

.modal-backdrop.show {
    opacity: .3;
}
