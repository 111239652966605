.img-text-teaser {
    position: relative;
    @media screen and (max-width: 767px) {
        margin-bottom:calc(15rem/16);
    }
}

.img-text-teaser .embed-responsive:after {
    content:'';
    display:block;
    position:absolute;
    left:0;
    right:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba(146, 209, 232, 0);
    transition: all 0.3s ease;
    z-index: 0;
}


.img-text-teaser:hover .embed-responsive:after {
    background-color: rgba(128, 183, 203, 0.4);
}

@media screen and (min-width: 767px) {
    .img-text-teaser:hover .img-text-teaser__body {
        transform: translateX(-10px) translateY(-10px);
    }
}
.img-text-teaser__body {
    background-color: #fff;
    margin-top: calc(-70rem / 16);
    padding: 1.25rem 0 0 1.25rem;
    transition: all 0.3s ease;
    @media screen and (max-width: 767px) {
        padding:calc(10rem/16) calc(15rem/16);
        position: absolute;
        margin-top:0px;
        bottom: 0;
        width: 100%;
    }
}
.img-text-teaser__title {
    font-size:calc(25rem/16);
    line-height:calc(30/25);
    font-family:var(--font-default-bold);
    color:var(--color-primary);
    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
    }
}

.img-text-teaser__text {
    font-size:calc(16rem/16);
    line-height:calc(28/16);
    margin-top:calc(10rem/16);
    margin-bottom:calc(15rem/16);
}

.img-text-teaser__additional-info {
    font-size:calc(13rem/16);
    margin-bottom:calc(13rem/16);
    @media screen and (max-width: 767px) {
        line-height:1;
    }
}
.img-text-teaser__link {
    display: flex;
    align-items: center;

    color:var(--color-primary);
    font-size:calc(16rem/16);
}

.img-text-slider__content-wrapper {
    position: absolute;
    bottom: 0;
    left:0;
    right:0;
    @media screen and (max-width: 767px) {
        position:relative;
        margin-top:calc(-30rem/16);
    }
}


.img-text-slider__content {
    background-color:#fff;
    padding: 1.75rem 2rem .625rem;
    color:var(--color-text-default);
    @media screen and (max-width: 767px) {
        padding:calc(17rem/16) calc(15rem/16);
    }
}

.img-text-slider__title {
    font-size:calc(35rem/16);
    line-height:1;
    color:var(--color-primary);
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);

    }
}

.img-text-slider__info {
    font-size:calc(16rem/16);
    line-height:calc(35/16);
    font-family:var(--font-default-bold);
}

.img-text-slider__text {
    font-size:calc(16rem/16);
    font-family:var(--font-default-light);
    padding-top:calc(10rem/16);
    padding-bottom:calc(10rem/16);
}

.img-text-slider__link {
    display:block;
    color:var(--color-primary);
}

.img-text-slider__item {
    position:relative;
    padding-bottom:calc(20rem/16);
    padding-left:calc(60rem/16);
    @media screen and (max-width: 767px) {
        padding-left:calc(0rem/16);
        padding-bottom:calc(0rem/16);

    }
    @media screen and (max-width: 1199px) and (min-width: 768px){
        padding-left:calc(30rem/16);
    }
}

