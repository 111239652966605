.accordion-icon--change {
    color: var(--color-primary);
    transition: all 0.2s ease;
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
    }
}

.accordion-icon--change:before {
    content: var(--icon-accordion-close);
}

.collapsed .accordion-icon--change {
    transform:rotate(45deg);
}

.accordion__header {
    background-color: transparent;
    padding: 0;
}

.accordion__item {
    border: none;
    border-bottom: 1px solid var(--color-grey) !important;
}

.accordion__header-link {
    padding: .75rem 1.25rem;
    display: block;
    background: transparent;
    transition: all 0.2s ease;
    font-size:calc(22rem/16);
    @media screen and (max-width: 767px) {
        font-size:calc(16rem/16);
        padding: .4rem 1rem;
    }
}

.accordion__header-link:not(.collapsed) {
    background: var(--bg-primary-gradient);
    color: #fff;
}

.accordion__header-link:not(.collapsed) .accordion-icon--change {
    color: #fff;
}

.accordion__body {
    padding: calc(20rem / 16) calc(31rem / 16);
}