.quick-filter {
    border: solid 1px;
    border-radius: 1rem;
    border-color: var(--color-primary);
    background-color: transparent;
    margin: .1rem;
    padding: 0 .6rem;
    font-size: 12px;
}
.quick-filter--group-parent {
    font-size:calc(14rem/16);
    text-transform:uppercase;
    color:var(--color-primary);
}