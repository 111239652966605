.watchlist__item {
    margin-top: calc(30rem/16);
    background-color: var(--color-lightgrey);
    transition: box-shadow 0.2s ease;
    cursor:pointer;
}
.watchlist__item:hover  {
    box-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}
.watchlist__item-header {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    padding: calc(20rem/16) calc(20rem/16);
    font-size: calc(22rem/16);
    font-family: var(--font-default-bold);
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16) calc(20rem/16);
        line-height: 1.5;
        border:none;
    }

}
.watchlist__item-icon {
    font-size: calc(50rem/16);
}
.watchlist__item-body__item {
    padding: calc(10rem/16) calc(20rem/16);
    border-left: 2px solid #fff;
    height:100%;
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16) calc(20rem/16);
        line-height: 1.5;
    }
}
.watchlist__item-logo {
    height: 100%;
    width: 100%;
    padding: calc(15rem/16);
    display:flex;
    justify-content:center;
    align-items:center;
    @media screen and (max-width: 767px) {
        max-width: calc(150rem/16);
    }
}
.watchlist__btn {
    border-radius: calc(12rem/16);
    background:#fff;
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
}
.watchlist__btn.is-added {
    background: var(--bg-primary-gradient);
    color: #fff;
    border-color:#fff;
}
.watchlist__item-header, .watchlist__item-body {
    @media screen and (min-width: 768px) {
        height:50%;
    }
}
.watchlist__item-body__item-remove {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    box-shadow:none;
    @media screen and (max-width: 767px) {
        background: var(--bg-primary-gradient);
        color:#fff;
        padding: calc(10rem/16) calc(20rem/16);
    }
}
.watchlist__item-body__item-remove:hover {
    background-color: var(--color-primary-light);
    color: #fff;
}
.watchlist__btn-is-added-text, .is-added .watchlist__btn-default-text {
    visibility:hidden;
    display:none;
}
.is-added .watchlist__btn-is-added-text {
    visibility:visible;
    display:block;
}
