
.form-box {
    position:relative;
    overflow:hidden;
    width:100%;
    padding: .625rem 0px .4375rem 1.25rem;
    height:calc(65rem/16);
    display:flex;
    flex-direction: column;
    @media screen and (max-width: 767px){
        margin-bottom: calc(10rem/16);
    }
}

.form-box__text-area {
    height:auto;
}

.form-box:before {
    content:'';
    display:block;
    width: 100%;
    height: 85%;
    border-left:1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    border-radius: 0 10px 0;
    bottom: 0;
    left: 0;
    position:absolute;
    pointer-events:none;
}

.form-box.has-error:before {
    border-color:var(--color-danger);
}

.form-box__label {
    font-size:calc(11rem/16);
    line-height:calc(12/11);
    font-family:var(--font-default-light);
}


.form-box__content {
    font-size:calc(16rem/16);
    font-family:var(--font-default-light);
    line-height:calc(18/16);
    border:none;
    width: 100%;
    padding:0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-top:auto;
}

.form-box__content::-ms-expand {
    display: none;
}

.form-box__content:focus {
    box-shadow:none;
    color:var(--color-primary);
}

.form-box__select-icon {
    position: absolute;
    right: 0;
    bottom: 25px;
    font-size: calc(7rem/16);
    pointer-events: none;
    color:var(--color-primary-light);
}


.form-control:disabled, .form-control[readonly] {
    background-color:transparent;
}

select.form-box__content:disabled + .form-box__select-icon {
    color:var(--color-lightgrey);
}