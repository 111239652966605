body {
    color: var(--color-text-default);
    font-family: var(--font-default-light);
    font-size: var(--font-size-default);
    line-height:var(--line-height-default);
}

b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
}

h1, .h1 {
    font-size: calc(60rem/16);
    line-height:calc(69/60);
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:calc(25rem/16);
    }
}
h2, .h2 {
    font-size: calc(45rem/16);
    line-height:calc(50/45);
    font-family:var(--font-default-bold);
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
    }
}
h3, .h3 {
    font-size: calc(35rem/16);
    line-height:calc(40/35);
    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
    }
}
h4, .h4 {
    font-size: calc(30rem/16);
    line-height:calc(35/30);
    font-family:var(--font-default-bold);
}
h5, .h5 {
    font-size: calc(25rem/16);
    line-height:calc(30/25);
    font-family:var(--font-default-light);
    @media screen and (max-width: 767px) {
        font-size:calc(18rem/16);
    }

}
h6, .h6 {
    font-size: calc(22rem/16);
    line-height:calc(25/22);
    font-family:var(--font-default-bold);
}

.text-primary {
    color: var(--color-primary) !important;
}