.ribbon {
    padding:calc(10rem/16) calc(15rem/16);
    position:absolute;
    z-index: 2;
    border-radius: 0 calc(4rem/16) calc(4rem/16) 0;
    color: #fff;
    max-width: calc(200rem/16);
    display: block;
    height: auto;
    top: calc(10rem/16);
    font-size:calc(16rem/16);
    margin-left:calc(-14rem/16);
    font-family:var(--font-default-bold);
    line-height: 1.3;
    background:var(--bg-primary-gradient);

}
.ribbon:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: calc(-14rem/16);
    width: 0;
    height: 0;
    z-index: -1;
    white-space: normal;
    border: calc(7rem/16) solid var(--color-primary-dark);
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.ribbon--blue {
    background:var(--ribbon-blue);
}
.ribbon--blue:before {
    border: calc(7rem/16) solid var(--ribbon-blue-dark);
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.ribbon--purple {
    background:var(--ribbon-purple);
}
.ribbon--purple:before {
    border: calc(7rem/16) solid var(--ribbon-purple-dark);
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.ribbon--green {
    background:var(--ribbon-green);
}
.ribbon--green:before {
    border: calc(7rem/16) solid var(--ribbon-green-dark);
    border-bottom-color: transparent;
    border-left-color: transparent;
}
.ribbon--brown {
    background:var(--ribbon-brown);
}
.ribbon--brown:before {
    border: calc(7rem/16) solid var(--ribbon-brown-dark);
    border-bottom-color: transparent;
    border-left-color: transparent;
}