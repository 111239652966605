html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}
body {
    position: relative;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
    body {
        width: calc(982rem/16);
    }
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem/16);
        max-width: 100%;
        overflow-x: hidden;
    }
}
.container.container {
    max-width: calc(1470rem/16);
    width: 100%;
    @media screen and (max-width:1470px) {
        max-width:100%;
    }
}
.container.container--narrow {
    max-width: calc(920rem/16);
}

.page-wrapper {
    overflow-x:hidden;
    @media screen and (min-width: 1981px) {
        max-width: 1980px;
    }
    margin: 0 auto;
    background: #fff;
    @media screen and (max-width: 1199px) {
        overflow-x:hidden;
    }
}

.page-wrapper-intranet {
    overflow-x:hidden;
    background: #fff;
    position:relative;
    @media screen and (min-width: 768px) {
        padding-left:220px;
    }
    @media screen and (min-width: 1981px) {
        max-width: 1980px;
    }
}