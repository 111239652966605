.filter-radio {
    cursor:pointer;
    @media screen and (max-width: 767px) {
        width: 100%;
    }
}

.filter-radio__input {
    clip: rect(0,0,0,0);
    position: absolute;
}

.filter-radio__input:checked ~
.filter-radio__btn {
    background: var(--color-secondary);
    color: #FFF;
}

.filter-radio__btn {
    display: flex;
    align-items: center;
    height: calc(43rem/16);
    padding: calc(11rem/16) calc(29rem/16);
    background: #FFF;
    color: var(--color-text-default);
    border: 1px solid var(--color-text-default);
    border-radius: calc(21.5rem/16) ;

    transition: background ease .5s;

    @media screen and (max-width: 767px) {
        justify-content: center;
    }

}