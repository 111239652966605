.contact-teaser__body {
    background-color: #fff;
    transform: translateX(-35px);
    transition:all 0.4s ease;
    margin-top: calc(35rem / 16);
    height: 100%;
    width:calc(100% + 35px);
    padding: calc(20rem / 16) 0  calc(20rem / 16) calc(30rem / 16);
    @media screen and (max-width: 767px) {
        margin-top:calc(10rem/16);
        transform: translateX(0px);
        width:100%;
    }
}

.contact-teaser:hover .contact-teaser__body {
    transform: translateX(-55px) translateY(-20px);
}

.contact-teaser__title {
    font-family:var(--font-default-bold);
    font-size:calc(25rem/16);
    line-height:calc(35/25);
    color:var(--color-primary);
}

.contact-teaser__details .icon {
    color:var(--color-primary);
}

.contact-teaser__details a:hover {
    color:var(--color-primary);
}
.contact-teaser__details .icon-location {
    font-size: calc(25rem/16);
}

.contact-teaser__details .icon-mail {
    font-size: calc(14rem/16);
}

.contact-teaser__details .icon-website {
    font-size: calc(19rem/16);
}

.contact-teaser__details .icon-phone {
    font-size: calc(20rem/16);
}

.contact-teaser__details .icon-link-plus {
    font-size: calc(23rem/16);
}


.contact-teaser__details .icon-clock {
    font-size: calc(21rem/16);
}

.contact-teaser__detail{
    margin-top:calc(5rem/16);
}

.contact-teaser__details {
    font-family: var(--font-default-light);
    font-size:1rem;
}

.contact-teaser__infos {
    position:absolute;
    left:0;
    bottom:0;
    display:flex;
}

.contact-teaser__info {
    background-color:#fff;
    width:calc(40rem/16);
    height:calc(30rem/16);
    color:var(--color-primary-light);
    display:inline-flex;
    justify-content:center;
    align-items:flex-end;
    font-size:calc(21rem/16);
    transition: all 0.2s ease;
}
.contact-teaser__info .icon-gift {
    font-size: calc(17rem/16);
}

.contact-teaser__info + .contact-teaser__info {
    border-left:1px solid var(--color-lightgrey);
}