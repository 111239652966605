.productgrid {

}

.productgrid__filter {
    @media screen and (min-width: 768px) {
        margin-top:6.5rem;
    }
}

.productgrid__filter-item {
    margin-bottom:1.5rem;
}

.productgrid__filter-heading {
    font-size:1rem;
    margin-bottom:.5rem;
    font-family: var(--font-default-bold);
}




/* teaser */

.product-teaser {
    position:relative;

    @media screen and (max-width: 767px) {
        margin-bottom:3rem;
    }
}

.product-teaser__body {
    padding: calc(15rem/16) 0;
}
.product-teaser__title {
    font-size:calc(22rem/16);
    font-family:var(--font-default-bold);
    line-height:calc(28/22);
    margin:.125rem 0;

    @media screen and (max-width: 767px) {
        font-size:calc(20rem/16);
    }
}

.product-teaser__price {
    font-size:calc(26rem/16);
    color:var(--color-primary);
    font-family:var(--font-default-bold);
    line-height:calc(28/22);
    white-space:nowrap;

    @media screen and (max-width: 767px) {
        font-size:calc(24rem/16);
    }
}
.product-teaser__anfrage {
    font-size:calc(16rem/16);
    line-height:1;
    color:var(--color-primary);
    font-family:var(--font-default-bold);
}

.pds__price {
    font-size:calc(26rem/16);
    color:var(--color-primary);
    font-family:var(--font-default-bold);
    line-height:calc(28/22);
}

.product-teaser__old-price {
    opacity: .7;
    font-size:1rem;
    text-decoration: line-through;
}

.product-teaser__location {
    position: relative;
}
.product-teaser__location .icon {
    position: relative;
    top:.5rem;
    font-size: 1.625rem;
    margin-right:.5rem;
    color:var(--color-primary);
}
.pds__category,
.product-teaser__category {
    position:absolute;
    left:0;
    bottom:0;
    z-index: 1;
}

.pds__meta,
.product-teaser__meta {
    position:absolute;
    left:0;
    top:0;
    z-index: 1;
}
.product-teaser__meta {
    top:-1px;
}

.pds__label,
.product-teaser__label {
    display: inline-block;
    padding:.375rem .875rem;
    background-color:var(--color-secondary);
    color:#fff;
    font-size:calc(14rem/16);
    line-height:calc(16/14);
    text-align:center;
}
.pds__label--sale,
.product-teaser__label--sale {
    background-color:var(--color-danger);
}
.pds__label--special,
.product-teaser__label--special {
    background: var(--bg-primary-gradient);
}


/* pds slider */



/* pds slider */
.zoomContainer {
    overflow: hidden;
}
.pds-slider {
    line-height:0;
}
@media screen and (max-width: 767px) {
    .pds-slider__main {
        touch-action: auto !important;
    }
}
.pds-slider__thumb-item {
    position: relative;
    cursor: pointer;
}
.pds-slider .slick-dots {
    display:none !important;
}

.pds-slider__arrow  {
    background-color:var(--color-lightgrey);
    color:var(--color-primary);
    font-size:calc(16rem/16);
    width:100%;
    height:3rem;
    text-align:center;
    @media screen and (max-width: 767px) {
        background-color:transparent;
        background: var(--bg-primary-gradient);
        color:#fff;
        width: calc(25rem/16);
        height:calc(50rem/16);
    }
}
.pds-slider__arrow.slick-prev {
    @media screen and (max-width: 767px) {
        position:absolute;
        top: 50%;
        right:auto;
        left:0;
        z-index: 2;
        transform: translateY(-50%);
    }
}
.pds-slider__arrow.slick-next {
    @media screen and (max-width: 767px) {
        position:absolute;
        top: 50%;
        left:auto;
        right:0;
        z-index: 2;
        transform: translateY(-50%);
    }
}
.pds-slider__arrow.slick-disabled {
    color:var(--color-grey);
}
.slick-slide:not(.slick-current) .pds-slider__thumb-item:after {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(255,255,255,0.3);
}