.img-zoom__icon {
    color:#fff;
    font-size:calc(45rem/16);
    padding-right:calc(25rem/16);
    padding-bottom:calc(25rem/16);
    position: absolute;
    bottom: 0;
    right: 0;
    @media screen and (max-width: 767px) {
        font-size:calc(30rem/16);
        padding-right:calc(10rem/16);
        padding-bottom:calc(10rem/16);
        padding-left:calc(10rem/16);
    }
    @media screen and (min-width:768px) {
        opacity:0;
        transition: opacity 0.2s ease;
    }
}

.img-zoom:hover .img-zoom__icon {
    opacity: 1;
}

.img-zoom__img{
    transform:scale(1);
    transition: all 0.2s ease;
    animation: scaleZoomOut 0.5s ease forwards;
}

.img-zoom:hover .img-zoom__img {
    animation: scaleZoom 1.1s forwards;
}

@keyframes scaleZoom {
    0% {
        transform:scale(1);
    }
    60% {
        transform:scale(1.11);
    }
    100% {
        transform:scale(1.1);
    }
}

@keyframes scaleZoomOut {
    0% {
        transform:scale(1.1);
    }
    100% {
        transform:scale(1);
    }
}
