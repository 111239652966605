.map-filter__accordion {
    padding: calc(30rem / 16) 0;
    @media screen and (max-width: 767px) {
        padding: calc(15rem / 16) 0;

    }
}
.map-filter__accordion-btn {
    width:100%;
    text-align:center;
    font-family:var(--font-default);
    font-size:calc(16rem/16);
}

.map-filter__accordion-btn:focus {
    outline:none;
    box-shadow:none;
}
.map-filter__accordion-btn__icon-wrapper {
    font-size:calc(43rem/16);
    color:var(--color-primary);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-top:calc(10rem/16);
    padding-bottom:calc(10rem/16);
}

.map-filter__accordion-btn--status {
    font-size:calc(8rem/16);
    background: var(--bg-primary-gradient);
    border-radius:50%;
    color:#fff;
    width:calc(20rem/16);
    height:calc(20rem/16);
    display: flex;
    justify-content:center;
    align-items:center;
    opacity:1;
    margin-left:calc(-5rem/16);
    margin-bottom:calc(-5rem/16);
}

.map-filter__accordion-item {
    padding-bottom:calc(32rem/16);
    @media screen and (max-width: 767px) {
        padding-bottom:calc(10rem/16);
    }
}