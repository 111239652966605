.btn {
    font-size:calc(18rem/16);
    line-height:calc(21/18);
}

.btn-primary {
    background: var(--bg-primary-gradient);
    border-color:transparent;
    transition:background 0.2s ease;
    border:none;
}

.btn-white {
    color: #FFF;
    border-color: #FFF;
}

.btn-primary:hover {
    background: var(--bg-primary-gradient-hover);
}

.btn-row {
    margin-left:calc(-10rem/16);
    margin-right:calc(-10rem/16);
}
.btn-row .btn  {
    margin-left:calc(10rem/16);
    margin-right:calc(10rem/16);
    margin-top:calc(10rem/16);
}

.btn--no-styling, .btn-no-styling {
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.btn-ghost {
    border:1px solid #fff;
}

.btn-ghost:hover {
    background-color:#fff;
    color:var(--color-primary);
}

.btn-outline-primary{
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);
}

.btn-outline-light{
    padding-top: calc(10rem/16);
    padding-bottom: calc(10rem/16);
    background-color: transparent;
}
