.hero {
    position: relative;
}

.hero:before {
    background: linear-gradient(180deg, rgba(57, 57, 57, 0.5) 0%, rgba(57, 57, 57, 0) 100%);
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.hero__body {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    text-align: center;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 767px) {
        justify-content: flex-end;
        padding-left:calc(10rem/16);
        padding-right:calc(10rem/16);
        padding-bottom:calc(50rem/16);
    }
}

.hero--has-icon-row .hero__body {
    justify-content: flex-end;
}

.hero__title {
    font-size: calc(80rem / 16);
    line-height: calc(92 / 80);
    font-family: var(--font-default-bold);
    text-shadow: 0px 0px 37px #000000;
    @media screen and (max-width: 767px) {
        font-size: calc(25rem / 16);
        line-height: calc(29 / 25);
    } @media screen and (max-width: 1199px) and (min-width: 768px) {
    font-size: calc(50rem / 16);
    line-height: calc(60 / 50);
} @media screen and (min-width: 767px) {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 1.2s, transform 0.9s ease-out;
    backface-visibility: hidden;

}
}

.animation-start .hero__title {
    @media screen and (min-width: 767px) {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
    }
}

.hero__subtitle {
    font-size: calc(22rem / 16);
    line-height: calc(30 / 22);
    margin-top: calc(18rem / 16);
    text-shadow: 0px 0px 37px #000000;
    @media screen and (max-width: 767px) {
        font-size: calc(15rem / 16);
        line-height: calc(22 / 15);
        font-family: var(--font-default-light);
        margin-top:calc(5rem/16);
    } @media screen and (min-width: 767px) {
    opacity: 0;
    transition: opacity 0.9s ease-out 0.5s;
    backface-visibility: hidden;
}
}

.animation-start .hero__subtitle {
    @media screen and (min-width: 767px) {
        opacity: 1;
        visibility: visible;
    }
}

.hero__bg-img {
    @media screen and (min-width: 767px) {
        transition: transform 6s ease-out;
        transform: scale(1);
    }
}

.animation-start .hero__bg-img {
    @media screen and (min-width: 767px) {
        transform: scale(1.03);
    }
}

.hero__icon-row {
    margin-top: calc(110rem / 16);
    margin-bottom: calc(100rem / 16);
    @media screen and (max-width: 1440px) and (min-width: 768px) {
        margin-top: calc(20rem / 16);
        margin-bottom: calc(20rem / 16);
    } @media screen and (max-width: 767px) {
    margin-top: calc(10rem / 16);
    margin-bottom: calc(0rem / 16);
}
}

.hero__icon-row__text {
    font-size: calc(20rem / 16);
    line-height: calc(24 / 20);
    font-family: Var(--font-default);
    text-align: center;
    text-shadow: 0px 0px 15px #000000;
    @media screen and (max-width: 767px) {
        font-size: calc(14rem / 16);
    }
}

.hero__icon-row__icon {
    font-size: calc(50rem / 16);
    margin-bottom: calc(16rem / 16);
    text-shadow: 0px 0px 15px #000000;
    @media screen and (max-width: 767px) {
        font-size: calc(20rem / 16);
        margin-bottom: calc(5rem / 16);
    }
}



.hero__footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.hero__author-img-wrapper {
    width: calc(214rem/16);
    height: calc(214rem/16);
    margin: 0 auto;
    transform: translateY(50%);
    border-radius: 50%;
    @media screen and (max-width: 767px) {
        width:calc(120rem/16);
        height:calc(120rem/16);
    }
}

.hero__author-img {
    border-radius: 50%;
    border: 4px solid #fff;
}

.hero__logo {
    width:calc(216rem/16);
    @media screen and (max-width: 767px) {
        width:calc(150rem/16);
    }
}